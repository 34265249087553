.certs {
    padding: 50px 20px;
}

.certifications-container {}

.certs-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    text-align: center;
}

.certs-list img {
    /* width: 100px; */
    height: 100px;
    align-content: center;
}

.certs-card {
    border-radius: 5px;
    padding: 13px;
    width: calc(33.333% - 40px);
    min-width: 200px;
    transition: transform 0.5s ease;
}

.certs-card:hover {
    transform: translateY(-15px);
}

.service-card h2 {
    font-size: 1.5em;
    margin-bottom: 30px;
    color: #5e81ac;
}

.team-description {
    text-align: center;
}