.navbar {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: right;
    position: fixed;
    /* Makes the navbar fixed on the page */
    top: 0;
    /* Aligns the navbar at the top */
    left: 0;
    /* Ensures the navbar spans the full width of the viewport */
    background-color: #ffffff;
    /* Optional: Add a background color to the navbar */
    z-index: 1000;
    /* Optional: Ensures the navbar is on top of other content */
}

.navbar .links {
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.navbar a {
    text-decoration: none;
    padding: 6px;
    text-align: right;
    color: #3b4252;
}

.navbar h1 {
    padding-left: 20px;
    padding-right: 20px;
}

.navbar .links a {
    margin-left: 16px;
}

.navbar a:hover {
    color: #5e81ac;
}

@media (max-width: 480px) {
    .navbar .links a {
        font-size: 0.9em;
    }
}

/* Menu toggle button */
.menu-toggle {
    display: none;
    font-size: 2em;
    background: none;
    border: none;
    cursor: pointer;
    color: #3b4252;
    position: absolute;
    /* Absolute positioning */
    top: 20px;
    /* Position from the top */
    right: 30px;
    /* Position from the right */
}

/* Responsive styles */
@media (max-width: 768px) {

    .navbar .links {
        margin-left: auto;
        display: flex;
        align-items: center;
        overflow: hidden;
        /* Hide overflowing content during animation */
        transition: max-height 0.5s ease, opacity 0.5s ease;
        /* Smooth transitions for height and opacity */
        max-height: 0;
        /* Start with zero height */
        opacity: 1;
        /* Start with invisible links */
    }

    .navbar {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }

    .navbar .links {
        display: flex;
        /* Show links in a vertical column */
        flex-direction: column;
        width: 100%;
    }

    .navbar .links.show {
        max-height: 300px;
        /* Ensure this matches or exceeds the total height of your links */
        opacity: 1;

    }

    .navbar {
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    }

    .navbar .links a {
        margin: 10px 0;
        text-align: left;
        width: 100%;
    }

    .navbar .links strong {
        margin: 10px 0;
        text-align: left;
        width: 100%;
    }

    .navbar h1 {
        margin-bottom: 10px;
        text-align: left;
        width: 100%;
    }

    .menu-toggle {
        display: block;
    }
}

@media (max-width: 480px) {
    .navbar .links a {
        font-size: 0.9em;
    }
}