@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
  margin: 0;
  font-family: "DM Sans";
  font-weight: 100;

}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 60px;
}

.App {
  text-align: left;
  background-color: #ffffff;
  color: #2e3440;
}

.App-header {
  padding-left: 20px;
  padding-right: 20px;
}

.main-heading {
  text-align: center;
  font-size: 180%;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
}

.img-logo {
  /* display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  position: relative; */

  /* Fullscreen height */
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}


section {
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 25px;
  }
}