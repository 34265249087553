.customers {
    padding: 50px 20px;
}

.customers-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    text-align: center;
}

.customers-list img {
    width: auto;
    height: 50px;
    align-content: center;
}

.customers-card {
    border-radius: 5px;
    padding: 13px;
    width: calc(33.333% - 40px);
    min-width: 200px;
    transition: transform 0.5s ease;
}

.customers-card:hover {
    transform: translateY(-15px);
}