.services {
    padding: 50px 20px;
}

.services-container {
    width: 100%;
}

.services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.service-card {
    border-radius: 16px;
    padding: 30px;
    width: calc(33.333% - 40px);
    min-width: 500px;
    transition: transform 0.5s ease;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.service-card p {
    color: #2e3440;
}

.service-card:hover {
    transform: translateY(-15px);
}

.service-card h2 {
    font-size: 1.5em;
    margin-bottom: 30px;
    color: #5e81ac;
}

.quotation-note {
    text-align: center;
    font-size: 0.8em;
}

/* Responsive styles */
@media (max-width: 768px) {
    .service-card {
        width: calc(80% - 20px);
        min-width: auto;
    }
}

@media (max-width: 480px) {
    .service-card {
        width: calc(100% - 20px);
        min-width: auto;
    }

    .service-card h2 {
        font-size: 1.2em;
    }

    .service-card p {
        font-size: 0.9em;
    }
}