.TypingTextHeading {
    font-size: 50px;
    text-align: center;
    margin: 100px;

    position: absolute;
    top: 0;
    /* Adjust as needed */
    left: 0;
    /* Center horizontally if needed */
    right: 0;
    /* Center horizontally if needed */
    z-index: 10;
    /* Ensure it's on top of other elements */
    text-align: center;
    /* Center the heading text if needed */
    /* Add typing animation and other styles */
}