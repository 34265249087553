.contact-description {
    font-size: 1.2em;
    margin-bottom: 20px;
    /* color: #ECEFF4; */
}

.contact-container {
    background-color: #2e3440;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #ffffff;
    /* text-align: center; */
}

.contact-container .contact-info {
    width: 50%;
    min-width: 350px;
    /* Can be in percentage also. */
    height: auto;
    margin: 0 auto;
    padding: 10px;
    position: relative;
}

.contact-info {
    font-size: 0.9em;
}

.contact-info p {
    margin: 5px 0;
}

.section-title {
    font-size: 2.5em;
    margin-bottom: 40px;
    /* color: #81A1C1; */
}